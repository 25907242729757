import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { StickyContainer } from '@sparefoot/react-core';

import { Container } from 'components/core/Page';
import { Paragraph} from 'components/core/Typography';
import { SearchBox } from 'components/search/SearchBox';
import { ClickToCall } from 'components/facility/ClickToCall';

import { phonePropType } from 'config/propTypes';

import './StickySearch.scss';

export default function StickySearch({
	hidden,
	onSearch,
	location,
	sticky,
	horizontal,
	callToBook, // sf_12127_ssdc_paid_landing_refresh
	phone, // sf_12127_ssdc_paid_landing_refresh
	getTollfreePhoneNumber, // sf_12127_ssdc_paid_landing_refresh
}) {
	return React.createElement(
		sticky ? StickyContainer : Fragment,
		sticky ? { collapse: hidden } : undefined,
		<div
			className={classnames(
				'sticky-search',
				{ hidden },
				{ horizontal },
				{ callToBook },
			)}
		>
			<Container>
				{horizontal ? (
					<Paragraph
						color="white"
						size="medium"
						weight="bold"
						last
						className="title"
					>
						Find Self Storage Near You
					</Paragraph>
				) : (
					<Paragraph
						color="white"
						className="search-text"
					>
						Search for other storage facilities near this one.
					</Paragraph>
				)}
				<SearchBox
					darkButton
					onSearch={onSearch}
					location={location}
					segmentPrefix="sticky search box"
					id="sticky-search"
					useBrowserLocation
				/>
				{callToBook && ( // sf_12127_ssdc_paid_landing_refresh
					<div className="call-container">
						<Paragraph
							color="white"
							weight="medium"
							last
						>
							OR
						</Paragraph>
						<ClickToCall
							phone={phone}
							onFetchNumber={getTollfreePhoneNumber}
						/>
					</div>
				)}
			</Container>
		</div>,
	);
}

StickySearch.propTypes = {
	hidden: PropTypes.bool,
	sticky: PropTypes.bool,
	onSearch: PropTypes.func.isRequired,
	location: PropTypes.string,
	horizontal: PropTypes.bool,
	callToBook: PropTypes.bool, // sf_12127_ssdc_paid_landing_refresh
	phone: phonePropType, // sf_12127_ssdc_paid_landing_refresh
	getTollfreePhoneNumber: PropTypes.func, // sf_12127_ssdc_paid_landing_refresh
};

StickySearch.defaultProps = {
	sticky: true,
};
