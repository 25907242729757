import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'components/core/Image';
import { Paragraph, Text } from 'components/core/Typography';

import './HomePageUnitCard.scss';

function HomePageUnitCard({ unit }) {
	return (
		<div className="unit-card">
			<div className="unit-card-image">
				<img
					src={unit.imageSrc}
					alt="image units"
					height="126"
                    width="126"
				/>
			</div>
			<Paragraph
				size="mediumLarge"
				weight="extraBold"
				color="darkGray"
				last
			>
				{unit.title}
			</Paragraph>
			<Paragraph
				size="small"
				weight="extraBold"
				color="placeholderGray"
				last
			>
				{unit.infoTag.measure}{' '}
				<Text
					weight="standard"
					color="placeholderGray"
				>
					{unit.infoTag.type}
				</Text>
			</Paragraph>

			<div className="info-cards">
				{unit.infoTagTypes.map((item) => (
					<div
						key={item}
						className="info-card"
					>
						<Paragraph
							size="smallMedium"
							weight="extraBold"
							color="secondaryBlue"
							last
						>
							{item}
						</Paragraph>
					</div>
				))}
			</div>
			<Paragraph
				size="standard"
				weight="standard"
				color="darkGray"
			>
				<Text weight="extraBold">Looks Like:</Text> {unit.looksLike}
			</Paragraph>
			<Paragraph
				size="standard"
				weight="standard"
				color="darkGray"
				last
			>
				<Text weight="extraBold">Fits:</Text> {unit.fits}
			</Paragraph>
		</div>
	);
}

HomePageUnitCard.propTypes = {
	unit: PropTypes.shape({
		title: PropTypes.string,
		imageSrc: PropTypes.string,
		infoTag: PropTypes.objectOf(
			PropTypes.shape({
				measure: PropTypes.string,
				type: PropTypes.string,
			}),
		),
		infoTagTypes: PropTypes.arrayOf(PropTypes.string),
		looksLike: PropTypes.string,
		fits: PropTypes.string,
	}),
};

export default HomePageUnitCard;
