import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@sparefoot/react-core';
import { H2, Paragraph, Text } from 'components/core/Typography';
import RightArrow from 'components/svg/RightArrow';
import classnames from 'classnames';
import './HomePageSectionHeader.scss';

export function HomePageSectionHeader({
	icon,
	header,
	description,
	link,
	theme = 'dark',
}) {
	const blackOrWhiteText = theme === 'dark' ? 'white' : 'black';
	const blueOrWhiteText = theme === 'dark' ? 'white' : 'priceBlue';

	return (
		<div className={classnames(theme, 'hp-section-header-container')}>
			{icon}

			<H2
				className="hp-section-header"
				size="megaMobile"
				weight="extraBold"
				color={blackOrWhiteText}
			>
				{header}
			</H2>

			<Paragraph
				className="hp-section-description"
				size="standardMedium"
				color="gray"
				weight="standard"
			>
				{description}
			</Paragraph>

			{!!link?.url && (
				<Link
					href={link.url}
					className="hp-section-header-link"
				>
					<Text
						weight="extraBold"
						color={blueOrWhiteText}
						last
					>
						{link.text}
					</Text>
					<RightArrow />
				</Link>
			)}
		</div>
	);
}

HomePageSectionHeader.propTypes = {
	icon: PropTypes.object.isRequired,
	header: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	link: PropTypes.object,
	theme: PropTypes.string,
};

export default HomePageSectionHeader;
