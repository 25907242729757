import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Container } from 'components/core/Page';
import { H1, Text } from 'components/core/Typography';
import { Image } from 'components/core/Image';
import BestPrice from 'assets/images/icons/best-price.svg';
import HomeHero from 'assets/images/icons/home-hero.svg';
import { SearchSection } from 'components/landing';

import './HomeHeroBanner.scss';

export const enhance = compose(withRouter);

export function HomeHeroBanner() {
	return (
		<section className="hero-section">
			<Container className="top-row">
				<div className="text-column">
					<H1
						color="black"
						weight="extraBold"
						size="mega"
						className="hero-heading-top"
					>
						Find the Perfect Storage Unit <br />
						<Text
							size="mega"
							className="best-price"
						>
							at the Best Price{' '}
						</Text>
						<Image
							alt="best price icon"
							src={BestPrice}
							className="best-price-image"
						/>
					</H1>
				</div>

				<div>
					<Image
						alt="home furnishing icon"
						src={HomeHero}
						className="home-hero-illustration"
					/>
				</div>
			</Container>
			<SearchSection />
		</section>
	);
}

export default enhance(HomeHeroBanner);
