import React from 'react';
import PropTypes from 'prop-types';
import { Paragraph } from 'components/core/Typography';
import { getInitials, generateStars } from 'utils/formatting';
import './ReviewCard.scss';

const ReviewCard = ({ title, stars, description, user, key }) => (
	<div
		className="review-card-container"
		key={key}
	>
		<div className="user-info-container">
			<div className="outer-circle">{getInitials(user)}</div>

			<div className="user-info-text">
				<Paragraph
					size="standardMedium"
					weight="extraBold"
				>
					{user}
				</Paragraph>

				<div className="stars-row"> {generateStars(stars)}</div>
			</div>
		</div>

		<div className="review-text-container">
			<Paragraph
				size="standardMedium"
				weight="extraBold"
			>
				{title}
			</Paragraph>
			<Paragraph>{description}</Paragraph>
		</div>
	</div>
);

ReviewCard.propTypes = {
	title: PropTypes.string,
	stars: PropTypes.number,
	description: PropTypes.string,
	user: PropTypes.string,
	key: PropTypes.string,
};

export default ReviewCard;
