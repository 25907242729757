import React from 'react';

import { Image } from 'components/core/Image';
import { Paragraph, H2 } from 'components/core/Typography';
import { Container } from 'components/core/Page';

import iconPhoto from 'assets/images/landing/homepage/why-image.png';
import swervy from 'assets/images/layout/wave-background-bottom-light-blue.svg';

import InfoCards from 'config/whychoose';

import './WhyPeopleChoose.scss';

export function WhyPeopleChoose() {
	return (
		<section className="why-people-choose-section">
			<Container className="why-people-choose-container">
				<div className="image-container">
					<img
						src={iconPhoto}
						alt="moving"
						width="190"
						height="201"
					/>
					<H2 size="large">Why People Choose SelfStorage.com</H2>
				</div>
				<div className="cards-container">
					{InfoCards.map((card) => (
						<div
							className="info-card"
							key={card.text}
						>
							<Image
								src={card.icon}
								alt={card.text}
							/>
							<Paragraph last>{card.text}</Paragraph>
						</div>
					))}
				</div>
			</Container>
			<Image
				src={swervy}
				role="presentation"
				className="swervy"
			/>
		</section>
	);
}

export default WhyPeopleChoose;
